<template>
  <div class="content03">
    <h2>お問い合わせ</h2>
    <div class="wrap">
      <form action="">
        <dl>
          <dt>ニックネーム<span class="required">必須</span></dt>
          <dd>
            <input
              type="text"
              class="name"
              id="name"
              v-model="contact.nickname"
            />
          </dd>
        </dl>
        <dl>
          <dt>メールアドレス<span class="required">必須</span></dt>
          <dd>
            <input type="text" class="mail" id="mail" v-model="contact.email" />
          </dd>
        </dl>
        <!-- <dl>
          <dt>お問い合わせ種別</dt>
          <dd class="select">
            <select name="type" id="type" v-model="contact.type">
              <option value="">----- お選びください -----</option>
              <option value="種別1">種別1</option>
              <option value="種別2">種別2</option>
              <option value="種別3">種別3</option>
            </select>
          </dd>
        </dl> -->
        <dl>
          <dt>お問い合わせ内容</dt>
          <dd>
            <textarea
              name="comment"
              id="comment"
              v-model="contact.body"
            ></textarea>
          </dd>
        </dl>
        <p class="submit">
          <input type="submit" value="送  信" v-on:click.prevent="confirm()" />
        </p>
        <p class="chui">
          ※返信メールはドメイン「gama-mog.com」から送信しております。受信設定で拒否等が⾏われている場合は、設定の変更をお願い致します。なお、詳しい設定の内容は、NTTドコモ、au、ソフトバンク等の契約会社にお問い合わせください。<br />
          ※iCloudアドレスでご登録のお客様でメールが届かない場合は、iCloudの「迷惑メールボックス」に⼊っている可能性があります。「迷惑メールボックス」をご確認頂き「迷惑メールではない」を押して解除してください。
          また、ご不明な点がございましたら、お問い合わせよりご連絡ください。
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Inquiry",
  data: function () {
    return {
      contact: {},
    };
  },
  methods: {
    confirm() {
      this.$store.dispatch("inputInquiry", this.contact);
      this.$router.push({ name: "InquiryConfirm" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_form_page.css" scoped></style>
<style lang="css" src="@/assets/css/style_form_parts.css" scoped></style>
